.ticket-message {
    margin: 45px 0;
    padding: 0 125px 100px;
    font-family: 'Roboto', sans-serif;
}

.ticket-message__item {
    width: 40%;
    margin: 15px 0;
}

.ticket-message__item--user {
    margin-left: auto;
}

.ticket-message__date {
    color: #808080;
    font-size: 12px;
    font-weight: 400;
    padding-left: 20px;
}

.ticket-message__date--user {
    text-align: right;
    padding-left: 0;
    padding-right: 20px;
}

.ticket-message__info {
    background: #3B4255;
    border-radius: 8px;
    overflow: hidden;
    padding: 16px 20px;
    margin-top: 7px;
}

.ticket-message__text {
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 400;
    word-break: break-all;
    white-space: pre-wrap;
}

.ticket-message__file {
    color: #55AAF9;
    cursor: pointer;
    margin-top: 25px;
    font-size: 14px;
    font-weight: 400;
    padding-top: 10px;
    border-top: 2px solid #808080;
}

.ticket-message__file span {
    margin-left: 10px;
    white-space: pre-wrap;
    word-break: break-all;
}

@media (max-width: 1200px) {
    .ticket-message {
        padding: 0 25px 100px;
    }

    .ticket-message__item {
        width: 45%;
    }
}

@media (max-width: 991px) {
    .ticket-message__item {
        width: 50%;
    }
}

@media (max-width: 767px) {
    .ticket-message__item {
        width: 60%;
    }
}

@media (max-width: 575px) {
    .ticket-message__item {
        width: 85%;

    }
}
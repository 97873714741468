.database {
    padding: 0 100px 25px;
    font-family: 'Roboto', sans-serif;
    color: #DDDDDD;
    margin-top: 32px;
}

.database__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
}

.database__wrapper--item {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.database__title {
    font-weight: 500;
    font-size: 18px;
    position: relative;
    display: flex;
    margin-right: 5px;
    align-items: center;
    justify-content: center;
    color: #252425;
    border-radius: 14px 14px 0 0;
    padding: 10px 40px 8px;
    transition: all .1s linear;
}

.database__title:hover svg path {
    fill: #DDDDDD;
}

.database__title svg {
    margin-right: 5px;
}

.database__title:hover {
    color: #FFFFFF;
    background: #3C3C3C;
}

.database__title--active {
    color: #DDDDDD;
    background: #171717;
}

.database__title--active svg path {
    fill: #FFFFFF;
}

.database__title:first-child {
    margin-left: 0;
}


.database__empty-project {
    font-weight: 400;
    font-size: 22px;
    margin-top: 25px;
    font-family: 'Roboto', sans-serif;
    color: #a9a9a8;
    text-align: center;

}

/* filter */

.filter {
    font-family: 'Roboto', sans-serif;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
}

.filter__search {
    display: flex;
}

.filter__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.filter__text {
    margin: 0 10px;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 400;
    padding: 7px;
    background: none;
    outline: none;
    border: none;
    display: flex;
    justify-content: center;
    color: #DDDDDD;
    cursor: pointer;
    position: relative;
}

.filter__text--chosen {
    box-shadow: 0 0 0 1px #FFFFFF;

}

.filter__text::after {
    content: '';
    position: absolute;
    bottom: 0;
    background: #FFFFFF;
    width: 0;
    height: 1px;
    transition: width .2s ease;
}

.filter__text--active {
    box-shadow: 0 0 0 3px;
}

.filter__text:hover::after {
    width: 100%;
}

.filter__text:first-child {
    margin-right: 15px;
    margin-left: 0;
    cursor: default;
}

.filter__text:first-child svg {
    margin-right: 5px;
}

.filter__techs--item {
    border: none;
    background: none;
    border-radius: 6px;
    padding: 4px 10px;
    color: #FFFFFF;
    text-align: center;
    font-size: 14px;
    cursor: pointer;

}

.filter__techs--item-active {
    background: #EBEDFF;
    color: black;
}

.filter__techs--item-active svg path {
    fill: #3B4255;
}

.filter__sort {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
}

.filter-techs--item-active {
    background: #EBEDFF;
    color: #000000;
}

.filter__input {
    transition: flex .5s ease-out;
}

.filter__input input {
    width: 100%;
    border-radius: 14px 14px 0px 0px;
    transition: all .5s ease-out;
}

.analysis-wrapper__search:focus .filter__input {
    flex: 0 0 40%;
}

.analysis-wrapper__search:focus {
    background: #171717;
    text-align: left;
    padding-left: 25px;
    padding-right: 25px;
}

.analysis-wrapper__search:focus::placeholder {
    color: #FFFFFF;
}

.analysis-wrapper__search:focus.analysis-wrapper__search--icon path {
    fill: #FFFFFF;
}

.filter__grid {
    margin-right: 30px;
    background: #3C3C3C;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    border: 1px solid transparent;
    padding: 2px;
    transition: all .1s linear;
}

.filter__grid:hover {
    border: 1px solid #55AAF9;
}

.filter__techs {
    margin-top: 10px;
}


/* Project database */

.project-database {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
}

.project-database--active{
    margin-top: 55px;
}
.project-database__item {
    margin: 20px 0;
    padding: 14px 22px;
    display: block;
    background: #3B4255;
    border-radius: 10px;
    color: #FFFFFF;
    flex: 0 0 48%;
}

.project-database__item--top {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.project-database__item--percent {
    color: #FFFFFF;
    flex: 0 0 35%;
    font-size: 13px;
}

.project-database__item--tech {
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
    color: #FFFFFF;
}

.project-database__item--tech h2 {
    white-space: nowrap;
}

.project-database__item--tech p {
    margin-top: 10px;
    color: #808080;
}

.project-database__item--tech svg {
    margin-right: 5px;
}

.project-database__item--status {
    color: #55AAF9;
    font-weight: 500;
    font-size: 14px;
}

.project-info__wrapper {
    position: relative;
}

.project-database__width {
    background: #55AAF9;
    position: absolute;
    left: 0;
    height: 2px;
}

.column-project__errors {
    cursor: pointer;
}

.column-project__uploaded {
    margin-bottom: 10px;
    color: #A9A9A8;
    font-weight: 400;
    font-size: 12px;
}

.column-project__team {
    background: #535353;
    border-radius: 4px;
    padding: 6px 10px;
    color: #55AAF9;
    font-weight: 500;
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
}

.column-project__team-count {
    color: #A9A9A8;
    font-weight: 400;
    font-size: 12px;
    margin-bottom: 8px;
}

.column-project__team-count span {
    color: #55AAF9;
    font-weight: 500;
}

.project-database__item--status--wrapper {
    position: relative;
}

.project-database__item--name {
    margin-top: 35px;
}

.project-database__item--name h4 {
    color: #FFFFFF;
    text-decoration: none;
}

.project-database__item--name h3 {
    font-size: 12px;
    color: #A9A9A8;
}

.project-database__item--statistic {
    margin-top: 35px;
}

.project-database__item--statistic h3 {
    font-size: 12px;
    color: #A9A9A8;
}

.project-database__item--percentage {
    margin-top: 12px;
}

.project-database__item--name h3 {
    font-size: 12px;
}

.project-database__item--info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.project-database__item--uploaded {
    margin-top: 12px;
}

.project-database__item--uploaded h3 {
    color: #808080;
    font-weight: 400;
    font-size: 12px;
}

.project-database__item--name h2 {
    font-size: 18px;
    font-weight: 400;
}

.project-database__item--name h4 {
    font-size: 14px;
    font-weight: 400;
    margin-top: 12px;
}

.project-database__item--warning {
    color: black;
    font-size: 14px;
    margin-top: 12px;
    display: flex;
    justify-content: flex-end;
}

.project-database__item--warning span {
    margin-right: 10px;
}

.project-database__item--buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 0 0 100%;
    margin-top: 25px;
}
.project__date{
    color: #B3B3B3;
    font-weight: 600;
    margin-left: auto;
    font-size: 16px;
}
.project-database__item--btn-wrapper {
    display: flex;
    justify-content: flex-end;
    position: relative;
}

.project-database__item--btn-wrapper svg {
    cursor: pointer;
}

.project-database__item--btn {
    font-size: 12px;
    cursor: pointer;
    background: transparent;
    border: none;
    border-radius: 6px;
    margin-left: 10px;
}


.project-database__item--options {
    position: absolute;
    top: 25px;
    right: 25px;
    background: #EBEDFF;
    border-radius: 6px;
    padding: 5px 10px;
}

.project-database__item--option {
    display: flex;
    align-items: center;
    color: #000000;
    font-size: 16px;
    cursor: pointer;
    justify-content: space-between;
}

.project-database__item--option p {
    margin-right: 10px;
}


@media (max-width: 1200px) {
    .database {
        padding: 0 15px;
    }

    .project-database__item {
        flex: 0 0 calc(100% - 15px);
        margin: 15px auto;
    }
}

@media (max-width: 991px) {
    .filter__grid {
        margin-right: 0;
    }
    .project-database--active{
        margin-top: 85px;
    }
    .database__empty-project{
        margin-top: 85px;
    }
    .database__wrapper--item {
        flex: 0 0 100%;
        justify-content: space-between;
        order: 2;
    }
}

@media (max-width: 767px) {
    .filter__search {
        flex-wrap: wrap;
        flex: 0 0 100%;
    }

    .filter__text {
        display: block;
        margin: 0;
        flex: 0 0 15%;
    }

    .filter__text:first-child {
        margin-right: 10px;
        margin-left: 0;
        flex: 0 0 20%;
    }


    .filter__item {
        flex: 0 0 100%;
    }

    .filter__input {
        margin-left: auto;
        flex: 0 0 40%;
        display: block;
    }
    .project-database--active{
        margin-top: 100px;
    }
    .database__empty-project{
        margin-top: 100px;
    }
}

@media (max-width: 575px) {
    .filter__item {
        flex-wrap: wrap;
        flex: 0 0 100%;
    }

    .database {
        padding: 0;
    }

    .team-settings {
        padding: 0 15px;
    }

    .project-database--active{
        margin-top: 80px;
    }
    .database__empty-project{
        margin-top: 80px;
    }
    .filter__input {
        flex: 0 0 50%;
    }

    .database__wrapper--item {
        flex: 0 0 100%;
        justify-content: space-between;
        order: 2;
    }

    .database__title {
        flex: 0 0 33%;
        margin: 0;
        padding: 10px 0 8px;
        font-size: 12px;
    }

    .project-database__item--tech h2{
        font-size: 14px;
    }
    .filter__text, .filter__text:first-child {
        flex: 0 0 25%;
        text-align: left;
        padding: 4px;
        margin: 10px 0;
    }
}
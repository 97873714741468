.column-project {
    background: #3B4255;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 20px;
    flex: 0 0 100%;
    border-radius: 10px;
    margin: 10px auto;
    font-size: 14px;
    color: #FFFFFF;
    flex-wrap: wrap;
}

.column-project__top {
    display: flex;
    justify-content: space-between;
    flex: 0 0 100%;
}

.column-project__titles {
    position: relative;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    flex: 0 0 40%;
    justify-content: space-between;
    text-decoration: none;
    color: inherit;
}

.column-project__techs-wrapper {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.column-project__title {
    font-size: 18px;
    font-weight: 500;
}

.project-database__progress {
    position: absolute;
    width: 100%;
    right: 0;
    bottom: -5px;
    height: 2px;
    background: #C6C7C7;
}

.column-project__techs {
    color: #808080;
    margin: 0 20px 0 0;
}

.column-project__techs span {
    margin-right: 5px;
}

.column-project__statistic {
    flex: 0 0 15%;
}

.column-project__warning {
    color: black;
    font-size: 14px;
    margin-left: 12px;
    display: flex;
    justify-content: flex-end;
}

.column-project__warning span {
    margin-right: 10px;
}

.column-project__btn {
margin-left: auto;
    margin-top: 10px;
}


@media (max-width: 575px) {
    .column-project__title{
        font-size: 14px;
    }
}
.ticket-list {
    display: flex;
    justify-content: space-between;
    width: 80%;
    padding: 0 15px 0 50px;
    flex-wrap: wrap;
    margin-top: 90px;
}

.ticket-list__title {
    margin: 64px 0 24px;
    padding-left: 50px;
    color: #FFFFFF;
    font-weight: 400;
    font-size: 19px;
}


@media (max-width: 1200px) {
    .ticket-list {
        width: 100%;
    }
}

@media (max-width: 991px) {
    .ticket-list {
        padding: 0 15px;
        margin-top: 100px;
    }
    .ticket-list__title{
        padding-left: 15px;
    }
}

@media (max-width: 767px) {

}

@media (max-width: 575px) {
.ticket-list{
    margin-top: 100px;
}
}
.unsubscribe {
    padding: 110px 42px 42px;
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    justify-content: center;
    align-items: center;
}

.unsubscribe__icon {
    cursor: pointer;
    top: 42px;
    left: 43px;
    position: absolute;
}

.unsubscribe__title {
    font-weight: 600;
    text-align: center;
    font-size: 50px;
    color: #5ABE60;
    font-family: 'Roboto', sans-serif;
    margin-bottom: 44px;
}

.unsubscribe__text {
    font-weight: 500;
    font-size: 25px;
    font-family: 'Roboto', sans-serif;
    width: 72%;
    color: #FFFFFF;
    margin: 0 auto 25px;
}

.unsubscribe__text span {
    color: #23D86B;
}

.unsubscribe__text span:last-child {
    text-decoration: underline;
    cursor: pointer;
    margin-left: 5px;
}

.unsubscribe__img {
    max-width: 100%;
    height: auto;
}


@media (max-width: 1199px) {
    .unsubscribe__title{
        font-size: 47px;
    }
    .unsubscribe__text{
        font-size: 23px;
    }
}

@media (max-width: 991px) {
    .unsubscribe__title{
        font-size: 42px;
        text-align: center;
        margin-bottom: 15px;
    }
    .unsubscribe__text{
        font-size: 20px;
        text-align: center;
    }
}

@media (max-width: 767px) {
    .unsubscribe__title{
        font-size: 38px;
    }
    .unsubscribe__text{
        font-size: 18px;
    }
}
@media (max-width: 575px) {
    .unsubscribe__title{
        font-size: 25px;
    }
    .unsubscribe__text{
        font-size: 14px;
        width: 100%;
    }
}
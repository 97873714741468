.logout-modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 15000;
}

.logout-modal {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translateX(-50%);
    background: #EBEDFF;
    border-radius: 20px;
    padding: 40px;
    color: #000000;
    text-align: center;
}

.logout-modal__btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.change-password {
    width: 400px;
    top: 20%;
    padding: 40px 46px;
}

.logout-modal__title {
    font-size: 18px;
}

.logout-modal__text {
    font-size: 14px;
    margin: 10px 0 20px;
}

.logout-modal__btn {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 25px auto 0;
    border-radius: 6px;
    background: transparent;
    border: 2px solid #3B4255;
    color: #000000;
    flex: 0 0 35%;
    cursor: pointer;
    padding: 8px 0;
}

.logout-modal__btn:hover {
    background: #3B4255;
    color: #FFFFFF;
}

.logout-modal__btn--disabled {
    border-color: darkgrey;
    background: darkgrey;
    color: #FFFFFF;
}

.logout-modal__btn--disabled:hover {
    background: darkgray;
}

@media (max-width: 767px) {
    .change-password {
        max-width: 95%;
        padding: 25px;
        top: 10%;
    }

    .logout-modal {
        left: 10px;
        top: 10%;
        right: 10px;
        transform: unset;
    }
}

@media (max-width: 575px) {
    .change-password {
        max-width: 95%;
        padding: 25px;
        top: 10%;
    }
}
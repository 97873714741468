.hash {
    background: #EBEDFF;
    border-radius: 10px;
    flex: 0 0 67%;
    font-family: 'Roboto', sans-serif;
    position: relative;
}

.hash__titles {
    display: flex;
    color: #FFFFFF;
    position: relative;
    align-items: center;
    padding: 25px 25px 15px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    background: #79D171;
    flex-wrap: wrap;
}

.hash__title {
    flex: 0 0 50%;
    font-size: 16px;
    font-weight: 500;
}

.hash__close {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
}

.hash__filters {
    display: flex;
    margin-bottom: 10px;
    flex-wrap: wrap;
    align-items: center;
    padding: 25px 25px 0;
}

.hash__filter {
    font-weight: 400;
    font-size: 13px;
    color: #808080;
    cursor: pointer;
    padding: 4px 10px;

}

.switch {
    position: relative;
    display: inline-block;
    width: 56px;
    height: 29px;
    margin-left: 10px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.switch__wrapper {
    display: flex;
    align-items: center;
    flex: 0 0 100%;
    margin-top: 10px;
}

.switch__title {
    font-weight: 500;
    font-size: 12px;
    display: flex;
    align-items: center;
    font-family: 'Roboto', sans-serif;
    color: #808080;
}

.switch__title svg {
    margin-right: 7px;
}

.switch__text {
    font-family: 'PT Sans', sans-serif;
    border-radius: 4px;
    padding: 3px 17px;
    font-weight: 500;
    font-size: 14px;
    border: 1px solid #000000;
    cursor: pointer;
    transition: all .2s linear;
    color: #000000;
}

.switch__text--margin {
    margin-left: 22px;
}

.switch__text--active {
    background: #3B4255;
    color: #FFFFFF;
    border-color: transparent;

}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #3B4255;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

.switch__input:checked + .slider {
    background-color: #79D171;
}

.switch__input:focus + .slider {
    box-shadow: 0 0 1px #79D171;
}

.switch__input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.hash__filter--active {
    color: #FFFFFF;
    background: #3B4255;
    border-radius: 4px;

}

.hash__filter:first-child {
    margin-left: 0;
}

/* hash item */

.hash__wrapper {
    font-family: 'Roboto', sans-serif;
    position: relative;
    padding: 0 25px;
    flex-direction: column;
    display: flex;

}

.hash__ignore--wrapper {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.hash__ignore--wrapper svg {
    margin-left: 15px;
}

.hash__ignore--error {
    color: #FA5252;
    font-size: 12px;
    font-family: 'Roboto', sans-serif;
    margin-top: 10px;
}

.hash__wrapper--white {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)), #EBEDFF;
}

.count__ignore {

}

.hash__wrapper:first-child {
    margin-top: 10px;
}

.hash__wrapper--danger {
    background: #ECDAE5;
}

.hash__wrapper::after {
    content: '';
    position: absolute;
    display: block;
    height: 1px;
    width: 100%;
    left: 0;
    bottom: 0;
    background: #C6C7C7;
}

.hash__items {
    display: flex;
    flex: 0 0 100%;
    justify-content: space-between;
}

.hash__item {
    flex: 0 0 45%;
    margin: 15px 0 0;
    padding: 15px 0;
    word-break: break-all;
}

.hash__code {
    margin-bottom: 25px;
    word-break: break-all;
}

.hash__code--title {
    color: #808080;
    font-size: 14px;
    max-width: 100%;
    font-weight: 500;
    margin-right: 10px;
}

.hash__item--label {
    display: block;
    position: relative;
    padding-left: 25px;
    cursor: pointer;
    font-size: 18px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin: 25px 0;
}

.hash__item--label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.hash__item--label input:checked ~ .checkmark {
    background-color: #79D171;
}


.hash__item--label input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.hash__item--label .checkmark:after {
    left: 3px;
    top: 1px;
    width: 3px;
    height: 7px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.hash__code--value {
    word-break: break-word;
    font-size: 10px;
    font-weight: 500;
    margin-top: 5px;
    color: #55AAF9;
}

.hash__item--info {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 5px;
}

.hash__item--format {
    border: none;
    outline: none;
    background: #3680C5;
    border-radius: 6px;
    padding: 2px 8px;
    margin: 8px 0;
    color: #FFFFFF;
    font-size: 11px;
    letter-spacing: 1px;
    font-weight: 400;
    width: auto;
}

.hash__item--wrapper {
    margin: 10px 0;
    position: relative;
}

.hash__item--context {
    display: none;
}

.hash__item--popover-wrapper {
    position: relative;
    display: inline-block;
}

.hash__item--context {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    left: -150px;
    transform: translate(0, 10px);
    width: auto;
    background: #3B4255;
    display: none;
    padding: 10px 14px;
    border-radius: 4px;
    box-shadow: 4px 2px 6px rgba(0, 0, 0, 0.1), 10px 7px 40px rgba(0, 0, 0, 0.15);
    color: #FFFFFF;
    bottom: -50px;
    z-index: 5;
}

.hash__item--context::before {
    position: absolute;
    z-index: -1;
    content: "";
    right: calc(50% - 10px);
    top: -8px;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent #bfbfbf transparent;
    transition-duration: 0.3s;
    transition-property: transform;
}

.hash__item--popover-wrapper:hover .hash__item--context {
    z-index: 10;
    opacity: 1;
    visibility: visible;
    transform: translate(0, -20px);
    transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
}

.hash__ignore {
    display: flex;
    background: none;
    flex: 0 0 5%;
    border-radius: 4px;
    border: 1px solid #3C3C3C;
    cursor: pointer;
    color: #3C3C3C;
    font-weight: 500;
    font-size: 14px;
    padding: 4px 10px;
}

.popover__wrapper {
    position: relative;
    display: block;
    max-width: 100%;
    min-width: 100%;
}

.popover__title {
    border: none;
    outline: none;
    background: #3680C5;
    border-radius: 6px;
    padding: 4px 8px;
    margin: 8px 0;
    color: #FFFFFF;
    font-size: 11px;
    letter-spacing: 1px;
    font-weight: 400;
    display: inline-block;
}

.popover__content {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 30px;
    width: 200%;
    display: none;
    transform: translate(0, 10px);
    background: #3B4255;
    /* solid tab */
    box-shadow: 4px 2px 6px rgba(0, 0, 0, 0.1), 10px 7px 40px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    padding: 10px 14px;
    transition: opacity 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
}

.popover__content--right {
    right: 0;
}

.popover__content:before {
    position: absolute;
    z-index: -1;
    content: "";
    top: -8px;
    left: 15px;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent #3B4255 transparent;
    transition-duration: 0.3s;
    transition-property: transform;
}


.popover__wrapper:hover .popover__content {
    z-index: 10;
    opacity: 1;
    visibility: visible;
    display: block;
    overflow: auto;
    transform: translate(0, 2px);
    transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
    animation: animation .5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
}

.popover__message {
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
}

.hash__ignore--active {
    background: #3B4255;
}

.hash__ignore:hover {
    background: #3B4255;
    border-radius: 4px;
    color: #FFFFFF;
}

.popover__wrapper--counters {
    position: relative;
    display: block;

}

.popover__title--counters {
    border: none;
    outline: none;
    background: #3680C5;
    border-radius: 6px;
    padding: 4px 8px;
    margin: 8px 0;
    color: #FFFFFF;
    font-size: 11px;
    letter-spacing: 1px;
    font-weight: 400;
    display: inline-block;
}

.popover__content--counters {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    display: none;
    width: 100%;
    overflow-x: auto;
    transform: translate(0, 10px);
    background: #3B4255;
    /* solid tab */
    box-shadow: 4px 2px 6px rgba(0, 0, 0, 0.1), 10px 7px 40px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    padding: 10px 14px;
}


.popover__content--counters:before {
    position: absolute;
    z-index: -1;
    content: "";
    top: -8px;
    left: 75px;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent #3B4255 transparent;
    transition-duration: 0.3s;
    transition-property: transform;
}


.popover__wrapper--counters:hover .popover__content--counters {
    z-index: 10;
    opacity: 1;
    display: block;
    visibility: visible;
    transform: translate(0, 2px);
    transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
    animation: animation .5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
}

@keyframes animation {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.popover__message {
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
    word-break: break-word;
    word-wrap: break-word;
    display: inline-block;
    max-width: 100%;
}

@media (max-width: 1200px) {
    .hash {
        flex: 0 0 100%;
    }
}

@media (max-width: 991px) {

}

@media (max-width: 767px) {

}

@media (max-width: 575px) {
    .hash__wrapper {
        padding: 0 10px;
    }

    .hash__wrap {
        padding: 15px 15px 45px;
        align-items: flex-start;
    }

    .switch__text {
        font-size: 12px;
    }

    .hashes-page {
        bottom: 5px;
    }

    .hash__ignore {
        font-size: 12px;
    }
}

.login-top {
    background: linear-gradient(270deg, #5EBC84 0%, #6BBF44 100%);
    height: 157px;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    font-family: 'Roboto', sans-serif;
padding: 0 0 25px 100px;
    color: #252425;
}
.login-top__title{
    font-weight: 700;
    font-size: 28px;
}
.login-top__text {
    font-weight: 400;
    margin-top: 10px;
    font-size: 14px;
}
.login__link{
    font-size: 14px;
    cursor: pointer;
    font-weight: 500;
    color: #808080;
    text-decoration: underline;
    transition: all .1s linear;
    z-index: 15;
    text-align: center;
    display: block;
    margin-top: 10px;
}
.privacy__link--wrapper{
    display: flex;
    align-items: center;
}
.privacy__link{
    margin: 10px 15px 0;
    font-size: 14px;
    border-radius: 6px;
    background: #3B4255;
    color: #FFFFFF;
    padding: 5px 10px;
    transition: all .1s linear;
}
.privacy__link:hover{
    background: #000000;
}
.privacy__link:first-child{
    margin-left: 0;
}
@media (max-width: 1200px) {

}
@media (max-width: 991px) {

}
@media (max-width: 767px) {

}
@media (max-width: 575px) {

}
.upload {
    font-family: 'Roboto', sans-serif;
}

.upload__title {
    color: #FFFFFF;
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 35px;
}

.upload__intro {
    background: linear-gradient(270deg, #5EBC84 0%, #6BBF44 100%);
    font-family: 'Roboto', sans-serif;
    padding: 15px 100px 0;
    color: #252425;
}

.upload-form {
    background: #EBEDFF;
    border-radius: 10px;
    box-shadow: 4px 2px 6px rgba(0, 0, 0, 0.1), 10px 7px 40px rgba(0, 0, 0, 0.15);
    padding: 36px 24px;
    width: 100%;
    margin: 48px auto 40px;
}

.upload-form__file {
    display: none;
    visibility: hidden;
}

.upload-form__wrapper {
    flex: 0 0 25%;
}

.upload-form__title {
    text-align: left;
    font-weight: 700;
    font-size: 20px;
}

.upload-form__title-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.upload-form__arrow {
    border: solid black;
    border-width: 0 3px 3px 0;
    display: none;
    visibility: hidden;
    padding: 3px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    cursor: pointer;
    transition: all .2s linear;
}

.upload-form__arrow--hide {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
}

.upload-form__inputs {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    position: relative;
    flex-wrap: wrap;
}

.upload-form__error {
    color: #FA5252;
    margin-top: 10px;
    margin-left: auto;
    text-align: center;
    flex: 0 0 100%;
    font-size: 12px;
    font-weight: 400;
    justify-self: flex-end;
}

.errors {
    flex: 0 0 100%;
    margin-bottom: 25px;
}

.upload-form__success {
    text-align: center;
    white-space: pre-wrap;
    word-break: break-all;
    width: 50%;
    margin: 10px auto 0;
    font-size: 12px;
    font-weight: 400;
    justify-self: flex-end;
    color: #5EBC84;
}

.upload-project__loader {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin: 25px 0 15px;
    font-family: 'Roboto', sans-serif;
}

.upload-project__type {
    margin-left: 15px;
    font-weight: 500;
    font-size: 14px;
    color: #808080;
}

.upload-project__progress {
    display: flex;
    align-items: center;
    flex: 0 0 25%;
}

.upload-project__width {
    flex: 0 0 25%;
}

.upload-project__stop {
    display: block;
    flex: 0 0 50%;
    border-radius: 10px;
    padding: 12px 0;
    background: none;
    margin-left: 35px;
    border: 2px solid #FFFFFF;
    color: #808080;
    cursor: pointer;
}

.upload-project__close {
    margin-left: 10px;
    cursor: pointer;
}

.upload-project__result {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 0 0 40%;
}

.upload-project__percentage {
    margin-right: 15px;
}

.upload-project__done {
    color: #808080;
    font-size: 14px;
    margin-right: 10px;
}

.upload-project__done svg {
    margin-right: 7px;
}

.upload-project__open {
    background: #171717;
    color: #FFFFFF;
    border-color: transparent;
}

.upload-project__open:hover {
    border-color: #79d171;
}

.upload-project__info {
    display: flex;
    align-items: center;
    flex: 0 0 30%;
}

.upload-project__name {
    font-weight: 400;
    font-size: 18px;
}

/* custom select */

div.wrapper {
    display: flex;
    justify-content: center;
    flex: 0 0 24%;
}

.custom-select__container {
    width: 100%;
    position: relative;
}

.wrapper .custom-select__btn {
    border: 0;
    border-radius: 4px;
    background: #FFFFFF;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 14px;
    width: 100%;
    border-radius: 6px;
    padding: 25px 8px;
    position: relative;
    text-align: left;
}

.wrapper .custom-select__btn:hover {
    cursor: pointer;
}

.wrapper .custom-select__btn::after {
    content: '';
    border: solid black;
    border-width: 0 2px 2px 0;
    display: inline-block;
    position: absolute;
    right: 10px;
    padding: 4px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transition: all .3s linear;
}

.wrapper .custom-select__btn.expanded::after {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
}

.wrapper ul.options {
    display: none;
    list-style: none;
    padding: 0;
}

.wrapper ul.show {
    display: block;
    position: absolute;
    top: 70px;
    background: #FFFFFF;
    width: 100%;
    border-radius: 6px;
    overflow: hidden;
    z-index: 15;
}

.wrapper ul.options li {
    padding: 10px;
}

.wrapper ul.options li:active,
.wrapper ul.options li:focus,
.wrapper ul.options li:hover,
.wrapper ul.options li[aria-selected="true"] {
    background: #D8D9DA;
    cursor: pointer;
}

.options__list {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.upload-form__btn-wrapper{
    flex: 0 0 24%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}
.upload-form__btn {
    padding: 23px 8px;
    background: #3B4255;
    color: #FFFFFF;
    flex: 0 0 100%;
    font-size: 14px;
    border-radius: 6px;
    cursor: pointer;
    border: 2px solid transparent;
}

.upload-form__btn:hover {
    border-color: #79D171;
}


@media (max-width: 1200px) {
    .upload-form {
        width: 95%;
    }

    .upload__intro {
        padding: 15px 0 0;
    }
.upload-project__stop{
    flex: 0 0 35%;
}
}

@media (max-width: 991px) {
    div.wrapper, .upload-form__btn-wrapper {
        flex: 0 0 45%;
        margin: 7px 0;
    }

    .upload-project__loader {
        flex-wrap: wrap;
    }

    .upload-project__info {
        flex: 0 0 50%;
        margin-bottom: 10px;
    }
.upload-project__stop{
    flex: 0 0 25%;
}
    .upload-project__result, .upload-project__progress {
        flex: 0 0 100%;
    }
}

@media (max-width: 767px) {
    .upload-project__info {
        flex: 0 0 100%;
        margin-bottom: 10px;
    }

    .upload-project__result, .upload-project__progress {
        flex: 0 0 100%;
    }

    .upload-project__result {
        font-size: 12px;
    }

    .upload-project__stop {
        flex: 0 0 33%;
        font-size: 14px;
        padding: 6px 0;

    }

    .upload-project__name {
        font-size: 15px;
    }

}

@media (max-width: 575px) {
    div.wrapper, .upload-form__btn-wrapper {
        flex: 0 0 100%;
        margin: 7px 0;
    }
    .upload-form__error{
        flex: 0 0 100%;
        text-align: center;
    }
.upload-project__result{
    font-size: 11px;
}
    .upload-project__done {
        font-size: 12px;
    }

    .upload-project__stop {
        flex: 0 0 30%;
    }
.upload-project__percentage{
    margin-right: 8px;
}
    .upload-form__arrow {
        display: inline-block;
        visibility: visible;
    }

    .upload-form {
        padding: 22px 20px;
        margin: 10px auto;
    }
}
.aside {
    background: linear-gradient(180deg, #171717 0%, #3D3D3D 100%);
    padding: 15px 25px;
    font-family: 'Roboto', sans-serif;
    color: #FFFFFF;
    width: 70px;
    overflow-y: scroll;
    overflow-x: hidden;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    transition: all .3s ease-in-out;
    z-index: 150;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    flex-wrap: nowrap;
}

.aside--show {
    width: 250px;
}
.aside__wrap{
    flex-wrap: wrap;
}
.aside__toggler {
    cursor: pointer;
    padding: 5px 8px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    max-height: 28px;
}


.aside__toggler .aside__toggler--text{
    margin-left: 25px;
    font-weight: 400;
    overflow: hidden;
    display: inline;
    transition: all .1s ease;
    font-size: 12px;
     word-break: keep-all;
}
.aside__toggler--hide .aside__toggler--text{
    font-weight: 400;
    overflow: hidden;
    display: inline;
     word-break: keep-all;
    font-size: 12px;
    transition: all .1s ease;
    margin-left: 5px;
}
.aside__toggler:hover {
    background: #3B4255;
}

.aside__list {
    list-style: none;
    padding: 0;
    margin-top: 25px;
    min-width: 100%;
}

.aside__item {
    margin-bottom: 15px;
    cursor: pointer;
    display: flex;
    align-items: flex-start;
    flex: 0 0 100%;
    border-radius: 4px;
    padding: 5px;
}

.aside__item--hover {
    min-width: 30px;
}

.aside__item--hover:hover {
    background: #353A4B;

}

.aside__item--active {
    background: #353A4B;
}

.aside__item--info {
    margin-left: 14px;
    display: block;
    flex: 1 0 auto;
    overflow: hidden;
    transition: all .2s ease;
}

.aside__support--notification {
    color: #000000;
    font-weight: 500;
    font-size: 12px;
    background: #5ABE60;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    padding: 3px 7px;
    margin-left: auto;
}

.aside__item--info-hidden {
    margin-left: 27px;
}

.aside__item--wrapper {
    display: flex;
    width: 100%;
}

.aside__item--wrapper .aside__item--title {
    margin-bottom: 0;
}

.aside__item--team {
    margin-bottom: 20px;
}

.aside__item--title {
    font-weight: 500;
    font-size: 14px;
}

.aside__item--email {
    font-weight: 400;
    display: block;
    font-size: 14px;
    color: #DDDDDD;
    padding: 5px 3px 5px 7px;
    margin-bottom: 14px;
    word-break: break-all;
    border-radius: 6px;
    transition: all .1s linear;
}

.aside__item--arrow {
    border: solid #FFFFFF;
    border-width: 0 3px 3px 0;
    display: inline-block;
    margin-left: 50%;
    padding: 3px;
    transform: rotate(45deg) translateX(50%);
    -webkit-transform: rotate(45deg);
}

.aside__item--arrow--hide {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
}

.aside__item--email:hover {
    background: #353A4B;
}

.aside__item--email-active {
    background: #353A4B;
}

.aside__item--email--hide {
    display: none;
    visibility: hidden;
}

.aside__item--projects {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
    color: #FFFFFF;
}

.aside__create-team {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    border: none;
    background: none;
    color: #DDDDDD;
    width: 100%;
    font-size: 16px;
    cursor: pointer;
    border-radius: 4px;
    padding: 5px;
}

.aside__create-team svg{
    margin-left: 5px;
}
.aside__create-team:hover {
    background: #353A4B;
}

.aside__create-team--step {
    background: #79D171;
    color: #FFFFFF;
    padding: 3px 6px;
    font-size: 12px;
    margin-right: 7px;
    border-radius: 50%;
}

.create-team__wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 25000;

}

.create-team__backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(59, 66, 85, 0.6);
    backdrop-filter: blur(4px);

}

.create-team__form {
    margin: 0 auto;
}

.create-team__modal {
    position: fixed;
    top: 30%;
    left: 50%;
    transform: translateX(-50%);
    background: #EBEDFF;
    box-shadow: 4px 2px 6px rgba(0, 0, 0, 0.1), 10px 7px 40px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    padding: 30px;
    width: 750px;
    z-index: 250000;
    font-family: 'Roboto', sans-serif;
    color: #000000;
    text-align: center;
    cursor: default;
}

.add-member__modal {
}
.team-member-add__form{
    overflow-y: scroll;
    max-height: 350px;
}
.create-team__icon-wrapper {
    position: relative;
}

.create-team__steps-wrapper {
    display: flex;
    cursor: pointer;
    justify-content: center;
    margin-bottom: 35px;
    align-items: center;
}

.create-team__step-text {
    display: flex;
    align-items: center;
}

.create-team__step-text:first-child {
    margin-right: 20px;
}

.create-team__step-text--count {
    color: #FFFFFF;
    background: #79D171;
    border-radius: 50%;
    padding: 1.5px 6px;
    font-size: 12px;
    margin-right: 5px;
    border: 1px solid transparent;
}

.inactive {
    background: none;
    border-color: #79D171;
}

.inactiveText {
    color: #C6C7C7;
    font-weight: 400;
}

.create-team__step-text--title {
    font-size: 16px;
    font-weight: 500;

}

.create-team__title {
    font-size: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
}

.create-team__text {
    margin: 20px 0;
    font-size: 14px;
    font-weight: 400;
}

.create-team__icon {
    position: absolute;
    right: 10px;
    top: 25px;
    transform: translateY(-50%);
    cursor: pointer;
}

.create-team__icon:hover path {
    fill: #000000;
}

.create-team__fields {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 45px;
}

.create-team__email {
    position: relative;
    flex: 0 0 85%;
}

.create-team__fields--wrapper {
    flex: 0 0 70%;
    position: relative;
}

.create-team__input-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.create-team__error {
    position: absolute;
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
    color: #FA5252;
    font-size: 9px;
    right: 10px;
    bottom: 0;
}

.create-team__remove {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    right: 10px;
}

.first-input {
    border-top-right-radius: 6px !important;
    border-top-left-radius: 6px !important;
}

.create-team__input {
    flex: 0 0 80%;
    width: 100%;
    border-radius: 6px;
    margin-bottom: 15px;
    border: none;
    outline: none;
    display: block;
    background: #FFFFFF;
    padding: 17px 20px;
}

.create-team__member {
    border-radius: 0;
    margin-bottom: 0;
    border-bottom: 1px solid #C6C7C7;
    flex: 0 0 100%;
}

.create-team__add-input {
    flex: 0 0 85%;
    width: 100%;
    border: none;
    display: flex;
    cursor: pointer;
    margin-bottom: 22px;
    justify-content: space-between;
    align-items: center;
    background: #FFFFFF;
    padding: 17px 20px;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    text-align: left;
}

.create-team__add-input:hover {
    background: #3B4255;
    color: #FFFFFF;
}

.create-team__add-input:hover svg path {
    fill: #FFFFFF;
}

.create-team__input::placeholder {
    color: #C6C7C7;
    font-weight: 400;
    font-size: 14px;
}

.create-team__btn {
    flex: 0 0 30%;
    border-radius: 6px;
    border: 2px solid transparent;
    background: #3B4255;
    padding: 20px 0;
    white-space: nowrap;
    color: #FFFFFF;
    font-size: 14px;
    cursor: pointer;
}

.create-team__skip {
    font-weight: 400;
    font-size: 12px;
    color: #808080;
    margin-top: 5px;
    cursor: pointer;
}

.create-team__btn:hover {
    border-color: #79d171;
}


.create-team__search-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 5px;
}

.finded-projects__list {
    list-style: none;
    padding: 0;
    position: absolute;
    background: #FFFFFF;
    width: 100%;
    top: 60px;
    max-height: 350px;
    border-radius: 6px;
}

.finded-projects__item {
    text-align: left;
    padding: 15px 20px;
    cursor: pointer;
}

.aside-off {
}


.custom-select__container--member {
    width: 100%;
    position: relative;
}

.wrapper-member-item .custom-select__btn {
    border: 0;
    border-radius: 4px;
    background: transparent;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 16px;
    color: #000000;
    width: 100%;
    padding: 20px 8px;
    position: relative;
    text-align: left;
}
.custom-select__btn svg{
    margin-left: 15px;
}
.custom-select__btn {
    color: #000000;
    display: flex;
    align-items: center;
}

.wrapper-member-item .custom-select__btn:hover {
    cursor: pointer;
}

.wrapper-member-item .custom-select__btn::after {
    content: '';
    border: solid white;
    border-width: 0 2px 2px 0;
    display: inline-block;
    margin-left: 8px;
    margin-bottom: 2px;
    padding: 4px;
    padding: 4px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transition: all .3s linear;
}

.wrapper-member-item .custom-select__btn.expanded::after {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
}

.wrapper-member-item ul.options {
    display: none;
    list-style: none;
    padding: 0;

}

.wrapper-member-item ul.show {
    display: block;
    position: absolute;
    top: 50px;
    right: 0;
    background: #EBEDFF;
    width: 144px;
    color: #000000;
    border-radius: 6px;
    overflow: hidden;
    z-index: 5;
}

.wrapper-member-item__btn {
    border-radius: 6px;
    color: #FFFFFF;
    background: #C6C7C7;
    border: none;
    padding: 5px 10px;

}

.wrapper-member-item ul.options li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 5px;
}

.wrapper-member-item ul.options li:active .wrapper-member-item__btn,
.wrapper-member-item ul.options li:focus .wrapper-member-item__btn,
.wrapper-member-item ul.options li:hover .wrapper-member-item__btn,
.wrapper-member-item ul.options li[aria-selected="true"] .wrapper-member-item__btn {
    cursor: pointer;
    background: #3B4255;
}

@media (max-width: 1200px) {

}

@media (max-width: 991px) {
    .aside {
        position: fixed;
        top: 0;
        bottom: 0;
        overflow-y: scroll;
    }

    .aside--show {
        z-index: 10;
    }

    .create-team__error {
        font-size: 12px;
    }

    .create-team__modal {
        max-width: 95%;
    }
}

@media (max-width: 767px) {
    .create-team__modal {
        top: 10%;
        padding: 30px 10px;
    }

    .create-team__error {
        font-size: 11px;
    }
}

@media (max-width: 575px) {
    .aside {
        left: -200px;
    }

    .aside--show {
        left: 0;
        width: 100%;
    }

    .create-team__input {
        flex: 0 0 100%;
    }

    .create-team__btn {
        font-size: 12px;
        padding: 17px 5px;
        flex: 0 0 75%;
    }

    .create-team__search-icon {
        right: 2px;
    }

    .header__toggler {
        display: block;
        z-index: 153;
    }

    .create-team__error {
        font-size: 9px;
        right: 10px;
        bottom: 0;
    }
}
.login-wrap {
    padding: 0 75px 0 0;
    display: flex;
}

.login-wrapper {
    background: #EBEDFF;
    /* solid tab */
    position: relative;
    box-shadow: 4px 2px 6px rgba(0, 0, 0, 0.1), 10px 7px 40px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    flex: 0 0 50%;
    margin: -100px 0 30px auto;
    min-height: 350px;
    padding: 10px 0 20px;
}
.register-wrapper{
    flex: 0 0 40%;
}
.login__gif {
    position: absolute;
    display: flex;
}

.login__gif img {
    max-width: 100%;
    height: auto;
    object-fit: contain;
}

.login-toggler {
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-toggler__btn {
    font-weight: 700;
    font-size: 22px;
    background: none;
    padding-bottom: 8px;
    border: none;
    cursor: pointer;
    margin: 0 100px;
    position: relative;
}

.login-toggler__btn--acitve {
    position: relative;
}

.login-toggler__btn--acitve::after {
    content: '';
    position: absolute;
    background: #000000;
    border-radius: 6px;
    display: block;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
}


@media (max-width: 1200px) {
    .login-wrapper {
        width: 50%;
    }

    .login-wrapper {
        margin-top: 35px;
    }
}

@media (max-width: 991px) {
    .login-wrapper, .login__gif {
        flex: 0 0 75%;
    }

    .login-wrap {
        flex-wrap: wrap;
        padding: 0 5px;
    }
}

@media (max-width: 767px) {
    .login-toggler__btn {
        margin: 0 50px;
    }
    .login-wrapper, .login__gif {
        flex: 0 0 100%;
    }
    .login-wrap {
        padding: 0 15px;
    }

    .login-wrapper {
        margin: 100px 0 25px 0;
        width: 100%;
        padding: 15px 0;
    }
}

@media (max-width: 575px) {
    .login-toggler__btn {
        margin: 0 25px;
    }

}
.sign-form {
    margin-top: 40px;
}

.sign-form__input-wrapper {
    margin: 16px auto;
    width: 50%;
}

.sign-form__spinner-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999999;
    background: rgba(59, 66, 85, 0.7);
    border-radius: 6px;
}

.sign-form__spinner-wrapper svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}

.password__show__wrapper {
    position: relative;
}

.sign-form__btn {
    display: block;
    margin: 20px auto 0;
    background: linear-gradient(270deg, #5EBC84 0%, #6BBF44 100%);
    color: #FFFFFF;
    font-size: 14px;
    padding: 12px 0;
    width: 50%;
    cursor: pointer;
    border-radius: 10px;
    border: 1px solid transparent;
}

.sign-form__btn:hover {
    border-color: #79D171;
}

.sign-form__btn:active {
    background: #79d171;
}

.register-form__input-wrapper {
    width: 70%;
}

.register-form__btn {
    width: 70%;
}

.password__rules {
    width: 70%;
    font-family: 'Roboto', sans-serif;
    margin: 0 auto;
    color: #808080;
    font-weight: 400;
    font-size: 14px;
}

.modal__rules {
    width: 100%;
    text-align: left;
}

.password__rule {
    margin: 12px 0;
}

.password__rule span {
    margin-left: 15px;
}

.password__rules--wrong {
    color: #FA5252;
}

.password__rules--pass {
    color: #5ABE60;
}

.sign-form__haveNoAcc {
    color: #5ABE60;
    width: 50%;
    font-weight: 500;
    margin: 22px auto 0;
    text-align: center;
    font-size: 14px;
    display: block;
}

.register {
    margin: 3px auto 0;
    color: #5ABE60;
    font-weight: 500;
}

.register:hover {
    color: #79d171;
}

.sign-form__label, .sign-form__input {
    display: block;
    width: 100%;
    border: none;
    font-weight: 400;
    font-size: 14px;
    border-radius: 10px;
}

.sign-form__label {
    margin-bottom: 5px;
    padding-left: 10px;
}

.sign-form__input {
    padding: 12px 20px;
    outline: none;
}

.sign-form__input::placeholder {
    color: #C6C7C7;
    font-size: 14px;
}

.sign-form__remember {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 50%;
    margin: 0 auto;
}
.sign-up-form__remember{
    width: 75%;
}

.forgot__pass {
    font-weight: 400;
    font-size: 14px;
    color: #808080;
    cursor: pointer;
    background: none;
    border: none;
    outline: none;
}

.forgot__pass:hover {
    color: #79D171;
}

/* custom checkbox*/

.remember__label {
    display: block;
    position: relative;
    padding-left: 20px;
    font-size: 14px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}


.remember__checkbox {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
}

.remember__checkmark {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    height: 14px;
    width: 14px;
    background: #FFFFFF;
}

.remember__checkbox:checked ~ .remember__checkmark {
    background-color: #3b4255;
}

.remember__checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.remember__checkbox:checked ~ .remember__checkmark:after {
    display: block;
}

.remember__label:hover::before {
    background: #FFFFFF;
    border-radius: 50%;
    position: absolute;
    top: 45%;
    transform: translateY(-50%);
    left: 5px;
    content: '';
    height: 4px;
    width: 4px;
}

/* Style the checkmark/indicator */
.remember__label .remember__checkmark:after {
    left: 4px;
    top: 1px;
    width: 3px;
    height: 7px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.recaptcha {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 767px) {

    .sign-form__input-wrapper, .sign-form__remember, .sign-form__btn {
        width: 75%;
    }

}

@media (max-width: 575px) {
    .sign-form__input-wrapper, .sign-form__remember, .sign-form__btn {
        width: 90%;
    }

    .recaptcha {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

}
.errors__head {
    background: #3B4255;
    padding: 36px 16px 16px 22px;
    font-family: 'Roboto', sans-serif;
    color: #FFFFFF;
    width: 75%;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    font-size: 16px;
    margin-top: 50px;
    font-weight: 500;
}

.errors__body {
    background: #EBEDFF;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    padding: 28px 22px 22px;
    width: 75%;
}

.errors__item {
    padding-bottom: 30px;
    margin: 25px 0;
    position: relative;

}
.errors__item--title{
    word-break: break-all;
}
.errors__item:first-child {
    margin-top: 10px;
}

.errors__item::after {
    position: absolute;
    bottom: 0;
    content: '';
    width: 100%;
    background: #C6C7C7;
    height: 1px;
    left: 0;
}

.errors__item--text {
    display: inline-block;
    white-space: pre-wrap;
    max-width: 100%;
    word-wrap: break-word;
    margin-top: 25px;
}

@media (max-width: 1200px) {
    .errors__body, .errors__head {
        width: 100%;
    }
}

@media (max-width: 991px) {

}

@media (max-width: 767px) {

}

@media (max-width: 575px) {

}
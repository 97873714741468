.layout {
}

.layoutWrapper {
    display: flex;

}

.content {
    flex: 1 0 70%;
}

@media (max-width: 991px) {
    .content {
        padding-left: 70px;
    }
}
@media (max-width: 575px) {
    .content {
        padding-left: 0;
    }

}
.header {
    background: #171717;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'Roboto', sans-serif;
    color: #FFFFFF;
    padding: 35px 50px;
}

.header__username {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 5px;
}

.header__user-email {
    font-size: 14px;
}

.header__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header__title {
    font-weight: 500;
    font-size: 16px;
    margin-left: 15px;
    padding-right: 15px;
}

.header__subTitle {
    font-weight: 500;
    font-size: 16px;
    padding: 0 15px;
    border-left: 1px solid #5ABE60;

}

.header__logout {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.header__logout--text {
    color: #A9A9A8;
    font-weight: 400;
    font-size: 14px;
    margin-right: 10px;
    transition: all .1s linear;
}

.header__logout--text:hover {
    color: #FFFFFF;
}

.header__profile {
    display: flex;
    align-items: center;
    font-family: 'Roboto', sans-serif;
    padding-left: 10px;
}

.header__profile--teamId {
    font-weight: 400;
    font-size: 12px;
    font-family: 'Roboto', sans-serif;
    margin-right: 20px;
    border-right: 1px solid #5ABE60;
    border-left: 1px solid #5ABE60;
    padding: 5px 15px;
}

.header__profile--info {
    margin-right: 24px;
}

.header__profile--username {
    font-weight: 500;
    font-size: 14px;
    text-align: right;
}

.header__profile--email {
    font-weight: 400;
    font-size: 14px;
}

@media (max-width: 1200px) {

}

@media (max-width: 991px) {
    .header {
        padding: 35px 15px;
    }
    .header__logo, .header__title, .header__subTitle {
        display: none;
    }
}

@media (max-width: 767px) {
    .header__subTitle {
        font-size: 13px;
    }

}

@media (max-width: 575px) {
    .header {
        padding: 25px 10px;
    }



    .header__title {
        padding-right: 0;
    }

    .header__logout--text {
        font-size: 12px;
    }


    .header__profile--info {
        margin-right: 10px;
    }

    .header__profile--username {
        font-weight: 500;
        font-size: 12px;
        text-align: right;
    }

    .header__profile--email {
        font-weight: 400;
        font-size: 12px;
    }

    .header__wrapper {
        padding-left: 0;
    }
}
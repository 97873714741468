.counters {
    flex: 0 0 32%;
}

.counters__title {
    color: #DDDDDD;
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 16px;
}

.counters__wrapper {
    background: #EBEDFF;
    border-radius: 10px;
    overflow: hidden;
}

.counters-hashes {

}

.counters__close {
    top: 50%;
    cursor: pointer;
}

.counters__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    padding: 10px 15px;
    cursor: pointer;
}

.counters__item--active {
    background: #79D171;
}

.counters__item:hover {
    background: #79D171;
}

.counters__name {
    font-weight: 400;
}

.counters__info {
    display: flex;
    font-weight: 500;
    flex: 0 0 70%;
    justify-content: flex-end;
}

.counters__info--graph {
    width: 75px;
}

.counters__info--graph::after {
    width: 75%;
    background: red;
}

.counters__info--count {
    margin-right: 15px;
    font-size: 12px;
}

.counters__filters {
    flex: 0 0 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 35px;
}

.counters__sort, .counters__show {
    background: none;
    font-size: 14px;
    cursor: pointer;
    border: none;
    font-weight: 500;
}

.counters__sort span {
    margin-left: 7px;
}

.counters__show {
    margin-left: 15px;
    color: #FFFFFF;
}

.counter__hash {
    display: block;
    position: relative;
    padding-left: 25px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding-bottom: 25px;
}

.counter__hash--block {
    flex: 1 0 auto;
    padding-bottom: 10px;
    position: relative;
}

.counter__hash--error {
    position: absolute;
    top: 25px;
    right: 15px;
    transform: translateY(-55%);
}

.counter__hash input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 14px;
    width: 14px;
    background-color: transparent;
    border: 1px solid #79D171;
}

.counter__hash input:checked ~ .checkmark {
    background-color: #79D171;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.counter__hash input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.counter__hash .checkmark:after {
    left: 3px;
    top: 1px;
    width: 3px;
    height: 7px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.counter__hash--path {
    font-size: 14px;
    margin: 15px 0;
    align-items: center;
    flex: 0 0 10%;
}

.counter__hash--path:first-child {
    margin-bottom: 15px;
}

.counter__hash--title {
    color: #808080;
    margin-right: 21px;
}

.counter__hash--text {
    color: #000000;
    font-size: 14px;
    font-weight: 400;
    word-break: break-all;
    white-space: pre-wrap;
}

.counter__hash--format {
    margin-top: 0;
    width: auto;
    cursor: pointer;
    word-break: break-all;
    white-space: pre-wrap;
}


@media (max-width: 1200px) {
    .counters {
        flex: 0 0 100%;
        margin-bottom: 25px;
    }
}

@media (max-width: 991px) {

}

@media (max-width: 767px) {

}

@media (max-width: 575px) {
    .counter__hash--title {
        font-size: 12px;
    }
    .counter__hash--path{
        margin: 20px 0 15px;
    }

    .counter__hash--text {
        font-size: 12px;
    }
    .counter__hash--error{
        top: 12px;
    }
}
.project-sorting__title svg {
    margin-right: 7px;
}

.project-sorting__title {
    margin-right: 22px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;

}
.project-sorting {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 0 0 100%;
    position: relative;
}
.project-sorting--active::after{
    content: '';
    height: 1px;
    position: absolute;
    bottom: -15px;
    width: 100%;
    border-bottom: 1px solid #3C3C3C;
}
.project-sorting__reset{
    cursor: pointer;
    margin-left: 5px;
    display: flex;
    align-items: center;
}

.project-sorting__item {
    background: #252425;
    color: #DDDDDD;
    white-space: nowrap;
    font-size: 14px;
    font-weight: 400;
    border-radius: 6px;
    outline: none;
    padding: 6px 12px;
    margin: 2px 3px;
    cursor: pointer;
    border: 1px solid transparent;
    transition: all .1s linear;
}

.project-sorting__item:hover, .project-sorting__item--active {
    border-color: #55AAF9;
}

.project-sorting__options {
    position: absolute;
    border-radius: 6px;
    background: #252425;
    margin-left: 3px;
    margin-right: 15px;
    opacity: 0;
    margin-top: 30px;
    box-shadow: 0 0 0 1px #55AAF9;
    transition: all .2s ease-out;
}

.project-sorting__options--show {
    opacity: 1;
}

.project-sorting__btn--active svg path {
    fill: #000000;
}
.project-sorting__wrap{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.project-sorting__btn {
    background: none;
    border: none;
    color: #FFFFFF;
    letter-spacing: 2px;
    font-weight: 400;
    font-size: 14px;
    outline: none;
    padding: 8px 17px;
    border-right: 1px solid #000000;
    display: inline-block;
    cursor: pointer;
}

.project-sorting__btn:last-child {
    border-right: none;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}

.project-sorting__btn:first-child {
    border-left: none;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}

.project-sorting__btn--active {
    background: #55AAF9;
    color: #000000;
}

.project-sorting__wrapper {
    display: flex;
    margin-bottom: 50px;
    align-items: center;
    justify-content: space-between;
}
.member-sorting__wrapper{
    width: 100%;
    flex-wrap: wrap;
}
@media (max-width: 767px) {

    .project-sorting__item{
        flex: 0 0 10%;
        font-size: 11px;
    }
    .project-sorting__btn{
        font-size: 10px;
    }
    .project-sorting__options{
        margin-top: 10px;
    }
}
@media (max-width: 575px) {
   .project-sorting{
       overflow-x: scroll;
       overflow-y: hidden;
       margin-top: 10px;
   }
    .project-sorting__wrapper{
        padding: 0 10px;
    }
    .project-sorting__title{
     font-size: 12px;
        flex: 0 0 20%;
        margin-right: 10px;
        margin-top: 8px;
    }

    .project-sorting__item{
        flex: 0 0 10%;
        font-size: 11px;
    }
    .project-sorting__btn{
        font-size: 9.5px;
    }
    .project-sorting__options{
        left: 15px;
        max-width: 320px;
    }
}
@media (max-width: 440px) {
    .project-sorting__wrapper{
        max-width: 345px;
        justify-content: flex-start;
    }
}
.network-error__wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 100px;
}

.network-error__item {
    flex: 0 0 48%;
}
.network-error__item img{
    max-width: 100%;
}
.network-error__item:first-child{
    margin-top: 75px;
}

.network-error__title {
    font-style: normal;
    font-weight: 700;
    font-size: 46px;
    font-family: 'PT Sans', sans-serif;
    color: #5ABE60;
}

.network-error__text {
    font-weight: 400;
    font-size: 28px;
    font-family: 'Roboto', sans-serif;
    color: #FFFFFF;
    margin: 13px 0 23px;
}

.network-error__checklist {
    font-weight: 400;
    font-size: 28px;
    margin-bottom: 11px;
    font-family: 'Roboto', sans-serif;
    color: #FFFFFF;
    position: relative;
    padding-left: 22px;
}

.network-error__checklist:after {
    background: #79D171;
    content: '';
    height: 14px;
    width: 14px;
    border-radius: 50%;
    position: absolute;
    display: block;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}

@media (max-width: 1199px) {
    .network-error__title {
        font-size: 40px;
    }

    .network-error__text, .network-error__checklist {
        font-size: 22px;
    }
}

@media (max-width: 991px) {
    .network-error__title {
        font-size: 35px;
    }

    .network-error__text, .network-error__checklist {
        font-size: 18px;
    }
}

@media (max-width: 767px) {
    .network-error__wrapper{
        flex-wrap: wrap;
        margin-top: 35px;
    }
    .network-error__item{
        flex: 0 0 100%;
        margin-bottom: 16px;
    }
}

@media (max-width: 575px) {
    .network-error__wrapper{
        margin-top: 10px;
    }
    .network-error__title {
        font-size: 30px;
    }

    .network-error__text, .network-error__checklist {
        font-size: 14px;
    }
}
.server-work {
    padding: 60px 100px;
}

.server-work__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #FFFFFF;
}

.server-work__title {
    font-family: 'PT Sans', sans-serif;
    font-weight: 700;
    font-size: 64px;
    color: #5ABE60;
    margin: 24px 0 15px;
}

.server-work__text {
    font-weight: 400;
    font-size: 22px;
    font-family: 'Roboto', sans-serif;
    color: #FFFFFF;
}

.server-work__wrapper {
    display: flex;
    justify-content: space-between;
    font-family: 'Roboto', sans-serif;
    flex-wrap: wrap;
    margin-top: 75px;
}

.server-work__item {
    flex: 0 0 48%;
    color: #FFFFFF;
    position: relative;
    overflow: hidden;
}

.server-work__time--title {
    font-weight: 400;
    font-size: 22px;
    color: #FFFFFF;
    margin: 0 0 24px;
}

.server-work__time--wrap {
    display: flex;
    justify-content: space-between;
    color: #000000;
    background: #EBEDFF;
    border-radius: 10px;
    padding: 24px 50px;
    font-size: 64px;
}

.server-work__time--item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.title {
    font-size: 22px;
}

/* Contact Us*/

.contact {
    margin-top: 60px;
}

.contact__title {
    font-weight: 400;
    font-size: 22px;
}

.contact__socials {
    margin: 24px 0;
    display: flex;
    align-items: center;
}

.contact__social {
    display: flex;
    align-items: center;
    margin-right: 25px;
}

.contact__social svg {
    margin-right: 8px;
}

/* notification form*/

.notification__form {
    display: flex;
    align-items: center;
}

.notification__input {
    padding: 10px 12px;
    border: none;
    flex: 0 0 45%;
    outline: none;
    border-radius: 10px;
    margin-right: 21px;
}

.notification__input::placeholder {
    font-family: 'Roboto', sans-serif;
    color: #A9A9A8;
    font-weight: 400;
    font-size: 14px;
}

.notification__btn {
    border: 2px solid #55AAF9;
    border-radius: 10px;
    background: none;
    padding: 8px 12px;
    flex: 0 0 25%;
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    cursor: pointer;
    color: #55AAF9;
}

/* shapes */

.orange-shape {
    position: absolute;
    left: 0;
    top: 0;
}

.green-shape {
    position: absolute;
    bottom: 0;
    right: 0;
}

/* Cogs */

.small-cog {
    position: absolute;
    left: 60px;
    top: 30px;
    z-index: 1;
    animation: rotation 8s infinite linear;
}

.huge-cog {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    animation: rotationReverse 8s infinite linear;
}

.min-height {
    min-height: 500px;
}

.medium-cog {
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 25px;
    animation: rotation 8s infinite linear;
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}

@keyframes rotationReverse {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(-359deg);
    }
}

@media (max-width: 1400px) {
    .server-work {
        padding: 60px 20px;
    }

    .small-cog {
        left: 0;
    }

    .huge-cog {
        width: 300px;
    }

    .medium-cog {
        width: 240px;
        left: 35px;
    }

    .orange-shape {
        width: 300px;
    }

    .green-shape {
        width: 220px;
    }
}

@media (max-width: 1200px) {
    .small-cog {
        left: 15px;
        top: 50px;
        width: 150px;
    }

    .huge-cog {
        width: 270px;
        top: 0;
        right: 15px;
    }

    .medium-cog {
        width: 200px;
        left: 15px;
    }

    .orange-shape {
        width: 250px;
    }

    .green-shape {
        width: 220px;
    }
}

@media (max-width: 991px) {
    .server-work__title {
        font-size: 50px;
    }

    .server-work__text, .server-work__time--title, .contact__title, .title {
        font-size: 19px;
    }

    .server-work__item {
        flex: 0 0 100%;
    }

    .min-height {
        min-height: 500px;
    }

    .small-cog {
        left: 50px;
        width: 200px;
        top: 50px;
    }

    .huge-cog {
        width: 350px;
        top: -5px;
        right: 50px;
    }

    .medium-cog {
        width: 300px;
        left: 75px;
    }

    .server-work__time--wrap {
        font-size: 50px;
    }

    .orange-shape {
        min-width: 450px;
        height: 350px;
    }

    .green-shape {
        width: 300px;
        height: 250px;
    }
}

@media (max-width: 767px) {
    .server-work__title {
        font-size: 45px;
    }

    .notification__input {
        flex: 0 0 60%;
    }

    .notification__btn {
        flex: 0 0 30%;
    }

    .server-work__text, .server-work__time--title, .contact__title, .title {
        font-size: 18px;
    }

    .server-work__wrapper {
        margin-top: 25px;
    }

    .small-cog {
        left: 35px;
        width: 200px;
        top: 50px;
    }

    .huge-cog {
        width: 300px;
        top: -5px;
        right: 0;
    }

    .server-work__time--wrap {
        font-size: 40px;
    }

    .medium-cog {
        width: 250px;
        left: 25px;
    }

    .orange-shape {
        min-width: 300px;
    }

    .green-shape {
        width: 250px;
    }
}

@media (max-width: 575px) {
    .server-work__title {
        font-size: 25px;
    }

    .notification__input {
        flex: 0 0 50%;
    }

    .notification__btn {
        flex: 0 0 35%;
        padding: 10px 6px;
        font-size: 12px;
    }

    .server-work__time--wrap {
        font-size: 25px;
    }

    .server-work__text, .server-work__time--title, .contact__title, .title {
        font-size: 14px;
    }

    .server-work__time--wrap {
        padding: 15px 25px;
    }

    .small-cog {
        left: 0;
        width: 100px;
        top: 50px;
    }

    .huge-cog {
        width: 200px;
        top: -5px;
        right: 0;
    }

    .medium-cog {
        width: 150px;
        left: 0;
    }

    .orange-shape {
        width: 200px;
        min-width: 1px;
    }

    .green-shape {
        width: 150px;
    }
}
.oath__text{
    width: 50%;
    margin: 20px auto 5px;
    font-size: 14px;
    color: #808080;
    font-family: 'Roboto', sans-serif;
}
.register-oauth{
    width: 70%!important;
}

.oauth__google{
    background: #1D1D1D;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    color: #FFFFFF;
    margin: 0 auto 5px;
    border-radius: 10px;
    padding: 8px 0;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
    cursor: pointer;
    border: 1px solid transparent;
}
.oauth__google:hover{
    border-color: #000000;
}
.oauth__google svg{
    margin-right: 10px;
}


@media (max-width: 767px) {

    .oath__text, .oauth__google{
        width: 75%;
    }

}

@media (max-width: 575px) {
    .oath__text, .oauth__google{
        width: 90%;
    }

}
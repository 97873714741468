.tickets{
    padding-left: 50px;
    font-family: 'Roboto', sans-serif;
    color: #FFFFFF;
    justify-content: flex-start;
}
.project-sorting__member{
    display: flex;
    align-items: center;
}

@media (max-width: 1200px) {

}

@media (max-width: 991px) {

}

@media (max-width: 767px) {
    .project-sorting__item--additional{
        flex: 0 0 50%;
    }

}

@media (max-width: 575px) {
.tickets{
    padding-left: 15px;
    flex-wrap: wrap;
}
    .project-sorting__item--additional{
        flex: 1 0 65%;
        overflow: hidden;
    }
    .project-sorting__member{
        justify-content: space-between;
        order: -1;
    }
    .member-change-page{
        margin-top: 6px;
    }

}
.not-found {
    background: #FFFFFF;
    min-height: 100vh;
    color: #000000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'PT Sans', sans-serif;

}
.error__links{
    margin-top: 50px;
    font-weight: 500;
    font-size: 18px;
    font-family: 'Roboto', sans-serif;
    color: #A9A9A8;
    text-align: center;
    width: 35%;
}

.not-found__title {
    font-weight: 700;
    font-size: 44px;
}

.not-found__text {
    font-weight: 400;
    font-size: 22px;
    text-align: left;
    margin: 15px 0;
}

.not-found__btn {
    color: #000000;
    display: inline-block;
    border: 2px solid #000000;
    cursor: pointer;
    border-radius: 6px;
    padding: 5px 20px;
    font-weight: 700;
}
.not-found__btn:hover{
    background: #000000;
    color: #FFFFFF;
}
.ticket-item {
    background: #3B4255;
    padding: 14px 18px;
    border-radius: 6px;
    margin: 24px 0;
    flex: 0 0 47%;
    color: #A9A9A8;
    font-size: 14px;
    cursor: pointer;
    font-family: 'Roboto', sans-serif;
    position: relative;
}
.ticket-item__notification{
    position: absolute;
    top: -20px;
    right: 10px;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #5ABE60;
    border-radius: 50%;
    color: #000000;
    font-size: 11px;
    font-weight: 500;
}
.ticket-item__id{
 font-size: 18px;
}
.ticket-item__info{
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #FFFFFF;
    font-weight: 500;

    letter-spacing: 1px;
}
.ticket-item__status{
    color: #5ABE60;
    font-size: 14px;

}
.ticket-item__issue{
    margin: 16px 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.ticket-item__text{
    padding-top: 16px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    cursor: pointer;
}
.ticket-item__text p {
    flex: 0 0 90%;
    word-break: break-all;
}
.ticket-item__text::before{
    position: absolute;
    content: '';
    height: 1px;
    width: 100%;
    top: 0;
    left: 0;
    background: #A9A9A8;
}
.ticket-item__arrow{
    border: solid #A9A9A8;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    cursor: pointer;
    transition: all .3s linear;

}
.ticket-item__arrow--hide{
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
}


@media (max-width: 767px) {
.ticket-item{
    flex: 0 0 100%;
}
}

@media (max-width: 575px) {

}
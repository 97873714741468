.member-add__btn {
    background: #EBEDFF;
    border-radius: 6px;
    padding: 6px 12px;
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    justify-content: space-between;
    cursor: pointer;
    border: 1px solid transparent;
}

.member-add__btn:hover {
    border-color: #EBEDFF;
    background: #3B4255;
    color: #FFFFFF;
}

.member-add__btn:hover svg path {
    fill: #FFFFFF;
}

.member-add__btn svg {
    margin-left: 10px;
}

.member-add__wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    right: -200%;
    z-index: 2500;
    transition: all .5s ease;
}

.member-add__wrapper--show {
    right: 0;
    left: 0;
}


.member-add__backdrop {
    position: fixed;
    top: 0;
    right: -200%;
    bottom: 0;
    width: 100%;
    background: rgba(59, 66, 85, 0.6);
    backdrop-filter: blur(4px);
}

.member-add__backdrop--show {
    right: 0;
}

.member-add__form {
    width: 440px;
    background: #EBEDFF;
    overflow-y: scroll;
    position: absolute;
    font-family: 'Roboto', sans-serif;
    right: -200%;
    padding: 28px 40px;
    top: 0;
    z-index: 5;
    bottom: 0;
    transition: right .1s linear;
}

.member-add__form--show {
    right: 0;
}

.member-add__form--top {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.member-add__form--title {
    color: #000000;
    font-weight: 500;
    font-size: 18px;
}

.member-add__form--btn {
    background: #3B4255;
    border-radius: 6px;
    padding: 8px 0;
    color: #FFFFFF;
    display: flex;
    width: 50%;
    justify-content: center;
    margin: 25px auto 0;
    font-weight: 400;
    font-size: 14px;
    cursor: pointer;
    border: 2px solid transparent;
    transition: all .1s linear;
}

.member-add__form--btn:hover {
    border-color: #79d171;
}

.member-add__form--close {
    position: absolute;
    right: 10px;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    top: 10px;
}

.member-add__form--email {
    margin: 30px 0 0;
}

.member-add__form--username {
    margin: 10px 0 30px;
    display: flex;
    align-items: center;
    color: #000000;
    font-weight: 400;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
}

.member-add__form--username svg {
    margin-right: 10px;
}

.member-add__form--email-label {
    display: block;
    color: #000000;
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 12px;
}

.member-add__form--email-input {
    border: none;
    outline: none;
    border-radius: 6px;
    padding: 10px 12px;
    display: block;
    width: 100%;
}

.member-add__form--error {
    font-size: 14px;
    margin-top: 5px;
    color: red;
    font-family: 'Roboto', sans-serif;
}

.member-add__form--roles {
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
}

.remove__member {
    margin: 15px auto 0;
    display: block;
    text-align: center;
    width: 50%;
}

.member-add__form--role-title {
    color: #000000;
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 12px;
}

.member-add__form__roles-wrapper {
    flex: 0 0 70%;
}

.member-add__form--role {
    color: #000000;
    background: #FFFFFF;
    margin-bottom: 4px;
    padding: 8px 12px;
    border-radius: 6px;
    cursor: pointer;
}

.member-add__form--role-active {
    background: #3B4255;
    color: #FFFFFF;
}

.member-add__form--warning {
    font-weight: 400;
    font-size: 12px;
    color: #808080;
    margin-top: 12px;
    text-align: center;
}

.member-add__form--permissions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 50px;
    border-top: 1px solid #3B4255;
    padding-top: 10px;
    color: #FFFFFF;
}

.member-add__form--permission {
    color: #808080;
    font-weight: 400;
    font-size: 12px;
    margin-bottom: 15px;
    padding-left: 30px;
}

.member-add__form--permission .remember__checkmark:after {
    left: 5px !important;
    top: 2px !important;
}

.member-add__form--permission .remember__checkmark {
    height: 16px;
    width: 16px;
    border-radius: 4px;
}

@media (max-width: 767px) {
    .member-add__form {
        width: 360px;
        padding: 10px 25px;
    }

    .remove__member, .member-add__form--btn {
        width: 75%;
    }
}

@media (max-width: 575px) {
    .member-add__form {
        width: 100%;
    }
    .remove__member, .member-add__form--btn {
        width: 90%;
    }
    .member-add__btn{
        padding: 5px 8px;
        font-size: 12px;
        margin-top: 7px;
    }
}
.privacy__text{
    margin-bottom: 15px;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 400;


}
.privacy{
    margin-bottom: 100px;
    padding-left: 100px;
}
.privacy__list{
    padding-left: 25px;
}

@media (max-width: 575px) {
  .privacy{
      padding-left: 25px;
  }
}
.intersections {
    background: #3B4255;
    border-radius: 10px;
    padding: 35px 12px;
    min-height: 500px;
    flex: 0 0 32%;
    font-family: 'Roboto', sans-serif;
}

.intersections__sort-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.intersections__sort-btn {
    border: none;
    background: none;
    cursor: pointer;
    color: #DDDDDD;
    font-size: 14px;
}

.intersections__sort-btn--text {
    margin-left: 10px;
}

.intersections__sort--title {
    color: #55AAF9;
    font-size: 14px;
    font-weight: 500;
    margin-right: 15px;
}

.intersections__bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 25px;
    margin-bottom: 10px;
}

.intersections__sorting--select, .intersections__sorting--btn {
    border-radius: 6px;
    background: #ECEDF5;
    border: none;
    outline: none;
    padding: 6px 14px;
    cursor: pointer;
}

.intersections__sorting--btn {
    margin-left: 10px;
    padding: 7px 14px;
    font-size: 14px;
    font-weight: 400;
    color: #000000;
}

.intersections__hide {
    background: none;
    border: none;
    color: #DDDDDD;
    font-size: 15px;
    font-weight: 400;
    margin-right: 15px;
    cursor: pointer;
}

.intersections__hide span {
    margin-left: 12px;
}

.intersections__show svg {
    transform: rotate(180deg);
}


/* intersection */

.intersection {
    background: #EBEDFF;
    border-radius: 10px;
    margin: 10px 0;
    padding: 20px 0;
}

.intersection__top {
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.intersection__title {
    color: #808080;
    font-size: 14px;
}
.intersection__title h2 {
    margin-bottom: 10px;
    color: #000000;
}
.intersection__title h2 svg {
    margin-right: 5px;
}
.intersection__info--name {
    display: flex;
    cursor: pointer;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0 15px;
    margin-top: 20px;
    margin-bottom: 25px;
}

.intersection__info--title h2 {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 10px;
    word-break: break-all;
}

.intersection__info--title p {
    font-size: 14px;
    word-break: break-all;
}

.intersection__info--status {
    color: #55AAF9;
    font-size: 14px;
    word-break: break-all;
}

.intersection__list {
    list-style: none;
    padding: 0;
}

.intersection__item--total {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px;
    border-bottom: 1px solid #808080;
}

.intersection__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 10px;
    margin: 3px 0;
}



.intersection__item--percentage {
    background: #3B4255;
    height: 6px;
    border-radius: 6px;
    width: 60px;
    margin-left: 8px;
    position: relative;
}

.intersection__item--active {
    background: #79D171;
}

.intersection__item--percentage-info {
    height: 2px;
    top: 50%;
    max-width: 96%;
    transform: translateY(-50%);
    border-radius: 6px;
    left: 2px;
    position: absolute;
}

.intersection__item--info {
    display: flex;
    align-items: center;
}

.intersection__item--info span {
    font-weight: 500;
    font-size: 14px;
}

.intersection__item--title {
    font-size: 14px;
}


@media (max-width: 1200px) {
    .intersections {
        flex: 0 0 100%;
        margin-bottom: 25px;
    }
}

@media (max-width: 991px) {
    .container {
        max-width: calc(100% - 15px);
    }
}

@media (max-width: 767px) {

}

@media (max-width: 575px) {
    .swiper-button-prev:after, .swiper-button-next:after {
        font-size: 27px !important;
    }
}